import React from "react";
import "./Home.css";
import HeroSection from "./sections/hero-section/HeroSection";
import WhatWeHave from "./sections/whatwehave/WhatWeHave";
import WorkDisplay from "./sections/workDisplay/WorkDisplay";
import Aboutus from "./sections/aboutus/Aboutus";
import Service from "./sections/services/Service";
import Blogs from "./sections/blogs/Blogs";
import ServiceCarousel from "./sections/serviceCarousel/ServiceCarousel";
import Pricing from "./sections/pricing/Pricing";
import LetUsTalkCard from "../../components/footer/LetUsTalkCard";
import ContactForm from "../../components/contactForm/ContactForm";

const Home = ({setShowFloatForm, setFloatFormData}) => {
  return (
    <div className="Home-Container">
      <HeroSection setShowFloatForm={setShowFloatForm} setFloatFormData={setFloatFormData}/>
      <WhatWeHave />
      <ServiceCarousel />
      <WorkDisplay />
      <Aboutus />
      <Service />
      {/* <Pricing /> */}
      <Blogs /> 
      <LetUsTalkCard />
    </div>
  );
};

export default Home;
