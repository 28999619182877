import { scrollIntoView } from "seamless-scroll-polyfill";

export const scrollToTop = () => {
  scrollIntoView(document.getElementById("topOfPageRef"), {
    behavior: "smooth",
  });
};

export const scrollToAboutUs = () => {
  scrollIntoView(document.getElementById("aboutUsRef"), {
    behavior: "smooth",
    block: "start",
  });
};

export const scrollToService = () => {
  scrollIntoView(document.getElementById("serviceRef"), {
    behavior: "smooth",
    block: "start",
  });
};

export const scrollToPricing = () => {
  scrollIntoView(document.getElementById("pricingRef"), {
    behavior: "smooth",
    block: "start",
  });
};

export const scrollToContactForm = () => {
  scrollIntoView(document.getElementById("contactFormRef"), {
    behavior: "smooth",
    block: "start",
  });
};
