import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

import "./BlogPage.css";

const blogApiUrl = process.env.REACT_APP_BLOG_API_URL;

function BlogPage() {
  const { name } = useParams();

  const [blogData, setBlogData] = React.useState(null);

  React.useEffect(() => {
    name && getBlogData(name);
  }, [name]);

  const getBlogData = async (slug) => {
    const res = await axios.get(
      `${blogApiUrl}/api/blogs?filters[slug][$eq]=${slug}&populate[coverImg][fields][0]=name&populate[coverImg][fields][1]=url&populate=seo`
    );
    setBlogData(res?.data?.data[0]?.attributes);
  };

  return (
    <div className="blog-page">
      {blogData ? (
        <>
          <h1 className="feature-blog-title">{blogData?.title}</h1>
          <div className="cover-image">
            <img
              src={`${blogApiUrl}${blogData?.coverImg?.data?.attributes?.url}`}
              alt="blog-cover"
            />
          </div>
          <div className="info">
            <p>
              Published on: {new Date(blogData?.publishedAt).toDateString()}
            </p>
            <div className="social-contacts">
              <a
                href="https://www.instagram.com/hyreworks/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram
                  color="#838383"
                  size={18}
                  className="social-icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/hyre-works"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin
                  color="#838383"
                  size={18}
                  className="social-icon"
                  style={{ background: "transparent" }}
                />
              </a>
              <a
                href="https://x.com/HyreWorks"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter
                  color="#838383"
                  size={18}
                  className="social-icon"
                  style={{ background: "transparent" }}
                />
              </a>
            </div>
          </div>
          <BlocksRenderer
            content={blogData?.content}
            blocks={{
              paragraph: (props) => <p className="blog-para">{props.children}</p>,
              quote: (props) => <blockquote>{props.children}</blockquote>,
              code: (props) => (
                <pre>
                  <code>{props.plainText}</code>
                </pre>
              ),
              heading: ({ level, children }) => {
                switch (level) {
                  case 1:
                    return <h1 className="blog-h1">{children}</h1>;
                  case 2:
                    return <h2 className="blog-h2">{children}</h2>;
                  case 3:
                    return <h3 className="blog-h3">{children}</h3>;
                  case 4:
                    return <h4 className="blog-h4">{children}</h4>;
                  case 5:
                    return <h5 className="blog-h5">{children}</h5>;
                  case 6:
                    return <h6 className="blog-h6">{children}</h6>;
                  default:
                    return <p>{children}</p>;
                }
              },
              link: (props) => <a href={props.url}>{props.children}</a>,
              list: (props) => {
                if (props.format === "ordered") {
                  return <ol className="blog-ol">{props.children}</ol>;
                }

                return <ul className="blog-ul">{props.children}</ul>;
              },
              "list-item": (props) => <li className="blog-li">{props.children}</li>,
              image: ({ image }) => {
                return (
                  <div className="content-images">
                    <img
                      src={image.url}
                      width={image.formats.small.width}
                      height={image.formats.small.height}
                      alt={image.formats.small.alternativeText || ""}
                    />
                  </div>
                );
              },
            }}
            modifiers={{
              bold: (props) => <strong className="strong">{props.children}</strong>,
              italic: (props) => <em>{props.children}</em>,
              underline: (props) => <u>{props.children}</u>,
              strikethrough: (props) => <del>{props.children}</del>,
              code: (props) => <code>{props.children}</code>,
            }}
          />
        </>
      ) : (
        <div>Blog not found </div>
      )}
    </div>
  );
}

export default BlogPage;
