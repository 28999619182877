import React, { useRef, useState } from "react";
import "./Aboutus.css";
import Slider from "react-slick";
import { AboutusCarouselSettings } from "../../../../utils/AboutusCarouselSettings";
import aboutusImg1 from "../../../../assets/images/homePage/aboutusImg1.png";
import aboutusImg2 from "../../../../assets/images/homePage/aboutusImg2.png";
import aboutusImg3 from "../../../../assets/images/homePage/aboutusImg3.png";
// import InfoHeader from "../../../../components/info-header-reusable/InfoHeader";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Aboutus = () => {
  const [progressVal, setProgressVal] = useState(1.5);
  const aboutUsSliderRef = useRef();

  const aboutCarouselData = [
    {
      image: aboutusImg2,
    },
    {
      image: aboutusImg1,
    },
    {
      image: aboutusImg3,
    },
    {
      image: aboutusImg1,
    },
    {
      image: aboutusImg2,
    },
    {
      image: aboutusImg1,
    },
  ];

  const headingData = {
    title: "About Us",
    heading:
      "Welcome to HyreWorks, where talent meets opportunity! We are dedicated to revolutionizing the way businesses connect with top-tier talent and vice versa. With our innovative platform, we're committed to simplifying and streamlining the hiring process for employers while providing a seamless and rewarding experience for job seekers",
    description: (
      <>
        <p className="mb-2">
          At HyreWorks we understand the challenges faced by both employers and
          job seekers in today's competitive job market. That's why we've
          created a comprehensive solution that combines cutting-edge technology
          with personalized support to ensure the perfect match between
          employers and candidates.
        </p>
        <p className="mb-2">
          Employers can expect a robust suite of tools to effortlessly manage
          every aspect of the recruitment process, from posting job openings and
          screening candidates to scheduling interviews and making offers. Our
          advanced applicant tracking system (ATS) and powerful analytics
          empower employers to make data-driven decisions and optimize their
          hiring strategies for success.
        </p>
        <p className="mb-2">
          For job seekers, HyreWorks offers a user-friendly interface to explore
          exciting career opportunities, submit applications with ease, and
          showcase their skills and experience to potential employers. With our
          intuitive platform, finding your dream job has never been simpler.
        </p>
        <p>
          Whether you're a growing startup, a mid-sized company, or a Fortune
          500 corporation, HyreWorks is your trusted partner in building
          exceptional teams and driving business growth. Join us today and
          discover the future of hiring!
        </p>
      </>
    ),
  };

  const sliderChangeCallback = (currentSlide, nextSlide) => {
    let calc = (nextSlide / (aboutCarouselData.length - 1)) * 100;
    setProgressVal(calc);
  };

  return (
    <div className="aboutus-container" id="aboutUsRef">
      <div className="aboutUs-heading">
        <div className="aboutUs-title">
          <div className="aboutUs-title-dash"></div>
          <div className="aboutUs-title-text">{headingData.title}</div>
        </div>
        <div className="aboutUs-heading-and-desc">
          <div className="aboutUs-heading-text">
            {headingData.heading}
            <span style={{ color: "#17A7DD" }}>.</span>
          </div>
          <div className="aboutUs-heading-desc">{headingData.description}</div>
        </div>
      </div>
      {/* *********************************************************/}
      <div className="aboutus-carousel-outer-div">
        <Slider
          ref={aboutUsSliderRef}
          beforeChange={sliderChangeCallback}
          className="aboutus-slider-styling"
          {...AboutusCarouselSettings}
        >
          {aboutCarouselData.map((data, idx) => {
            return (
              <div className="aboutus-carousel-all-content" key={idx}>
                <img
                  src={data.image}
                  className="aboutus-img"
                  alt="aboutus carousel visuals"
                />
              </div>
            );
          })}
        </Slider>
        {/* progress bar of slider */}
        <div className="aboutus-slider-progressbar">
          <div
            className="aboutus-slider-progressDot"
            style={{ left: progressVal + "%" }}
          />
        </div>

        <div className="service-carousel-actions">
          <div
            className="left-btn"
            onClick={() => aboutUsSliderRef.current.slickPrev()}
          >
            <FaChevronLeft size={20} />
          </div>
          <div
            className="right-btn"
            onClick={() => aboutUsSliderRef.current.slickNext()}
          >
            <FaChevronRight size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
