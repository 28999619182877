import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import "./BlogList.css";

const blogApiUrl = process.env.REACT_APP_BLOG_API_URL;

function BlogsList() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = React.useState([]);
  React.useEffect(() => {
    getBlogsList();
  }, []);

  async function getBlogsList() {
    const res = await axios.get(
      `${blogApiUrl}/api/blogs?sort=id:desc&fields[0]=title&fields[2]=description&fields[3]=slug&fields[4]=publishedAt&populate[coverImg][fields][0]=name&populate[coverImg][fields][1]=url&populate[blogUrl][field][0]=url&populate=seo`
    );
    if (res?.data?.data?.length) {
      setBlogs(
        res?.data?.data?.map((item) => ({
          ...item?.attributes,
          id: item?.id,
          coverImg: item?.attributes?.coverImg?.data?.attributes,
        }))
      );
    } else {
      console.log("no blogs");
    }
  }

  return (
    <div className="blog-container">
      {blogs?.length ? (
        <>
          <div className="main-blog">
            <div className="main-post-image">
              <img
                src={`${blogApiUrl}${blogs[0]?.coverImg?.url}`}
                alt={blogs[0]?.coverImg?.name}
              />
            </div>
            <div className="main-blog-content">
              <p>{new Date(blogs[0]?.publishedAt).toDateString()}</p>
              <h1 className="main-blog-title">{blogs[0].title}</h1>
              <p className="main-blog-description">{blogs[0].description}</p>
              <button
                className="blog-readmore-btn-style"
                onClick={() => {
                  navigate(`/blog/${blogs[0].slug}`);
                }}
              >
                <p>Read More...</p>
                {/* <div className="readmore-btn-dash" /> */}
                {/* <MdOutlineArrowForwardIos fill="#3FA0EF" size="2rem" /> */}
              </button>
            </div>
          </div>
          <div className="remaining-blog-list">
            {blogs.slice(1).map((data, idx) => {
              return (
                <div className="blog-card" key={idx}>
                  <div className="other-singlepost-image">
                    <img
                      src={`${blogApiUrl}${data?.coverImg?.url}`}
                      alt={data?.coverImg?.name}
                    />
                    <p className="remaining-blog-list-publish-time tablet-hidden">
                      {new Date(data?.publishedAt).toDateString()}
                    </p>
                  </div>
                  <div className="blog-data-and-buttons">
                    <div className="content-wrapper">
                      <p className="remaining-blog-list-publish-time desktop-hidden">
                        {new Date(data?.publishedAt).toDateString()}
                      </p>
                      <h2>{data.title}</h2>
                      <p>
                        {data.description?.length > 135
                          ? data.description?.slice(0, 130) + "..."
                          : data?.description}
                      </p>
                    </div>
                    <button
                      className="blog-readmore-btn-style"
                      onClick={() => {
                        navigate(`/blog/${data.slug}`);
                      }}
                    >
                      <p className="learn-more">Learn More</p>
                      {/* <div className="readmore-btn-dash" /> */}
                      <MdOutlineArrowForwardIos fill="#3FA0EF" size="2rem" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div> No blogs found </div>
      )}
    </div>
  );
}

export default BlogsList;
