export const AboutusCarouselSettings = {
  slidesToScroll: 1,
  slidesToShow: 3,
  arrows: false,
  useTransform: false,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 3600,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        lazyLoad: true,
      },
    },
  ],
};
