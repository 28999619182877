import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import * as Dialog from "@radix-ui/react-dialog";
import "./FormModal.css";
import VideoModal from "../VideoModal/VideoModal";
import { MdClose } from "react-icons/md";

const Modal = ({ open, setOpen, popupMessage, modalTitle, children }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const [videoUrl, setVideoUrl] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (data) => {
    const formDataObject = {
      firstName: "",
      lastName: "",
      email: data.email,
    };

    const nameParts = data.firstName.trim().split(" ");
    if (nameParts.length > 1) {
      formDataObject.firstName = nameParts[0];
      formDataObject.lastName = nameParts[1];
    } else {
      formDataObject.firstName = nameParts[0];
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://digicoin-reboot.dmlabs.in/api/dml-newsletter",
        formDataObject
      );
      if (response.status === 200) {
        handleOnSuccess(response, formDataObject);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnSuccess = () => {
    reset();
    setOpen(!open);
    setVideoUrl(!videoUrl);
  };

  return (
    <div className="">
        <VideoModal open={videoUrl} setOpen={setVideoUrl} />

      <Dialog.Root
        onOpenChange={() => {
          setOpen(!open);
          reset();
        }}
        open={open}
      >
        <Dialog.Trigger asChild>{children}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="modalOverlay" />
          <Dialog.Content className="modalContent">
            <Dialog.Close asChild>
              <div className={"IconButton"}>
                <button aria-label="Close" onClick={() => reset()}>
                  <MdClose color="#000" style={{ border: "none" }} />
                </button>
              </div>
            </Dialog.Close>
            <Dialog.Title className="modalTitle">{modalTitle}</Dialog.Title>
            <Dialog.Description className="modalSubtitle">
              Enter your details to {popupMessage}
            </Dialog.Description>
            <form
              id="webform"
              action="#"
              name="WebToLeads5267894000001372001"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-item">
                <label htmlFor="firstName">
                  Enter your Name
                  {/* <span style={{ color: "red" }}>&#42;</span> */}
                </label>
                <input
                  type="text"
                  className="form-control "
                  name="firstName"
                  maxLength="40"
                  {...register("firstName", {
                    required: "Name is required",
                    maxLength: {
                      value: 40,
                      message: "Name must not exceed 20 characters",
                    },
                    pattern: {
                      value: /^[A-Za-z]{3,}(?: [A-Za-z]+)*$/,
                      message:
                        "Name must be more than 3 characters and can only contain alphabets",
                    },
                  })}
                />
              </div>
              {errors.firstName && (
                <span className="error">{errors.firstName.message}</span>
              )}

              <div className="form-item">
                <label htmlFor="email">Enter your Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  maxLength="100"
                  {...register("email", {
                    required: "Email is required",
                    maxLength: 100,
                    pattern: {
                      value:
                        /^(?!.*\.\.)([A-Z0-9._%+-]+)@[A-Z0-9-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
              </div>
              {errors.email && (
                <span className="error">{errors.email.message}</span>
              )}
              <button
                className="formSubmitBtn"
                id="formsubmit"
                name="Submit"
                type="submit"
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

export default Modal;
