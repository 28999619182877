import React from "react";
import { HiChevronRight } from "react-icons/hi";
import "./service.css";
import { cardData } from "./ServiceCardData";
const Service = () => {
  return (
    <>
      <div className="service-main" id="serviceRef">
        <div className="service-heading">
          <div className="service-title">
            <div className="service-title-dash"></div>
            <div className="service-title-text">Our Services</div>
          </div>
          <div className="service-heading-and-desc">
            <h3 className="service-heading-text">
              Redefining Hiring with Emerging Tech
              <span style={{ color: "#17A7DD" }}>.</span>
            </h3>
            {/* <div className="service-heading-desc">
              We are able to help you with the latest high tech solutions,
              thanks to our company culture, which is built an everyday learning
              and self - improvment from each and single task.
            </div> */}
          </div>
        </div>

        <div className="card-and-background-wrapper">
          {/****************************************/}
          <div className="service-cards">
            {cardData.map((item, key) => {
              return (
                <div className="individual-card" key={key}>
                  <h4 className="hoverText card-title">{item.title}</h4>
                  <div className="hoverText card-desc">{item.desc}</div>
                  {/* <div className="learnMore-and-arrow">
                    <div className="hoverText card-learn-more">Learn More</div>
                    <div className="card-arrow">
                      <div className="hoverText card-arrow-tail"></div>
                      <HiChevronRight
                        className="hoverText card-arrow-head"
                      />
                    </div>
                  </div> */}
                </div>
              );
            })}
          </div>

          {/****************************************/}
          <div className="service-background-div"> </div>
        </div>
      </div>
    </>
  );
};

export default Service;
