import React from "react";
import "./HeroSection.css";
import { FiChevronRight } from "react-icons/fi";
import FormModal from "../../../../components/modals/FormModal/FormModal";

const HeroSection = ({ setShowFloatForm, setFloatFormData }) => {
  const [subModal, setSubModal] = React.useState(false);
  return (
    <div className="hero-section-container" id="topOfPageRef">
      <h1 className="hero-heading">
        <span className="hero-heading-title">You’re</span>{" "}
        <span className="primary-color-text">Hired !</span>
      </h1>
      <div className="hero-description">
        Elevate your hiring experience with the power of{" "}
        <span className="ai">Artificial Intelligence</span> <br />
        Screen and interview thousands of candidates at scale -{" "}
        <span className="ai">anytime, anywhere.</span>
      </div>
      <div className="btn-wrap">
        <button
          className="hero-button"
          // onClick={() => {
          //   setShowFloatForm((prev) => !prev);
          //   setFloatFormData("Let's Connect with our Design Representative");
          // }}
          onClick={() => document.getElementById('lets-talk-form').scrollIntoView({ behavior: 'smooth' })}
        >
          <div>Start Hiring Now</div>
          <FiChevronRight className="hero-btn-right-arrow" />
        </button>
        <button
          className="hero-button demo-btn"
          onClick={() => {
            setSubModal(!subModal);
          }}
        >
          <div>Demo Video</div>
        </button>
      </div>
      <FormModal
        open={subModal}
        setOpen={setSubModal}
        popupMessage="watch the product video."
        modalTitle={"Product Video"}
      />
    </div>
  );
};

export default HeroSection;
