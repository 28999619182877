import React from "react";
import "./css/letUsTalkCard.css";
import { scrollToContactForm } from "../../utils/scrollTo";

const LetUsTalkCard = () => {
  return (
    <>
      <div className="talkCard-main">
          <div className="talCard-text">
            <div className="text-up">
              Connecting Talent with Opportunity: Your Next Hire Starts Here!
            </div>
            <div className="text-down">Find Your Dream Job</div>
          </div>
          <button
            className="talkCard-btn"
            onClick={() => scrollToContactForm()}
          >
            Request a Demo
          </button>
      </div>
    </>
  );
};

export default LetUsTalkCard;
