import React, { useState, useMemo } from "react";
import "./FloatingForm.css";
import { MdClose } from "react-icons/md";
import chatBubble from "../../assets/images/homePage/chatBubble.png";
import Select from "react-select";
import countryList from "react-select-country-list";

const FloatingForm = ({ setShowFloatForm, floatFormData }) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    companyName: "",
    message: "",
  });

  const [country, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const onCountryChange = (val) => {
    setCountry(val);
  };

  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    let finalData = { ...data, country };
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#c3cad9",
      minHeight: "25px",
      height: "25px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "25px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "25px",
    }),
  };

  return (
    <div className="floatingform-container">
      <button
        onClick={() => setShowFloatForm(false)}
        className="floatingform-close-btn"
      >
        <MdClose />
      </button>

      <div className="floatingform-header">
        <img src={chatBubble} alt="chat icon" />
        <p className="floatingform-header-title">
          {floatFormData}
        </p>
        <p className="floatingform-header-desc">
          Have a project in mind that you think we’d be a great fit for it?{" "}
        </p>
      </div>

      <form
        className="floatingform-inputs-container"
        autoComplete="off"
        onSubmit={onSubmitHandler}
      >
        <div>
          <label htmlFor="name">Your Name*</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Type your name"
            value={data.name}
            onChange={onChangeHandler}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Contact Email*</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="you@example.com"
            value={data.email}
            onChange={onChangeHandler}
            required
          />
        </div>
        <div>
          <label htmlFor="companyName">Company Name*</label>
          <input
            type="text"
            name="companyName"
            id="companyName"
            placeholder="Company name"
            value={data.companyName}
            onChange={onChangeHandler}
            required
          />
        </div>
        <div>
          <label htmlFor="country">Country*</label>
          <Select
            name="country"
            id="country"
            placeholder="Country name"
            options={options}
            value={country}
            onChange={onCountryChange}
            className="country-input-styling"
            required
            styles={customStyles}
            maxMenuHeight={180}
          />
        </div>
        <div>
          <label htmlFor="message">Your Message*</label>
          <textarea
            name="message"
            id="message"
            placeholder="Type your message..."
            value={data.message}
            onChange={onChangeHandler}
            required
          />
        </div>
        <p className="floatingform-agreement-msg">
          By submitting this form you agree to our terms and conditions and our
          Privacy Policy which explains how we may collect, use and disclose
          your personal information including to third parties.
        </p>
        <button className="floatingform-submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default FloatingForm;
