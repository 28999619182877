import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/home/Home";
import BlogPage from "../pages/blogs/blogPage/BlogPage";
import BlogsList from "../pages/blogs/BlogsList";

const index = ({setShowFloatForm, setFloatFormData}) => {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home setShowFloatForm={setShowFloatForm} setFloatFormData={setFloatFormData}/>} />
        <Route path="/blog" element={<BlogsList />} />
        <Route path="/blog/:name" element={<BlogPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default index;
