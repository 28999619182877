import React from "react";
import "./Blogs.css";
import InfoHeader from "../../../../components/info-header-reusable/InfoHeader";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../../utils/scrollTo";

const Blogs = () => {
  const navigate = useNavigate();
  const headingData = {
    title: "Blogs",
    heading: "Keep Up to Date with Our Latest Insights",
    description:
      "As a good digital agency Double Square also always share new things that we learn with you from our blogs.",
  };

  const blogApiUrl = process.env.REACT_APP_BLOG_API_URL;

  const [blogs, setBlogs] = React.useState([]);

  React.useEffect(() => {
    getBlogsList();
  }, []);

  async function getBlogsList() {
    const res = await axios.get(`
      ${blogApiUrl}/api/blogs?sort=id:desc&fields[0]=title&fields[2]=description&fields[3]=slug&populate[coverImg][fields][0]=name&populate[coverImg][fields][1]=url&populate[blogUrl][field][0]=url&pagination[pageSize]=4 `);
    if (res?.data?.data?.length) {
      setBlogs(
        res?.data?.data?.map((item) => ({
          ...item?.attributes,
          id: item?.id,
          coverImg: item?.attributes?.coverImg?.data?.attributes,
        }))
      );
    } else {
      console.log("no blogs");
    }
  }

  const clsname = ["other-single-blogpost", "hide-last-post"];

  return (
    <div className="blogs-container">
      <InfoHeader
        title={headingData.title}
        heading={headingData.heading}
        description={headingData.description}
      />
      {blogs?.length ? (
        <div>
          <div className="blogs-showcase-div">
            <div className="main-blog-post-div">
              <div className="main-post-image">
                <img src={blogApiUrl + blogs[0]?.coverImg?.url} alt={blogs} />
              </div>
              <div className="main-blog-content">
                <p>{blogs[0]?.title}</p>
                <p>{blogs[0]?.description}</p>
                <button
                  className="blog-readmore-btn-style"
                  onClick={() => {
                    scrollToTop();
                    navigate(`/blog/${blogs[0].slug}`);
                  }}
                >
                  <p>Read More</p>
                  <div className="readmore-btn-dash" />
                  <MdOutlineArrowForwardIos fill="#3FA0EF" size="2rem" />
                </button>
              </div>
            </div>
            <div className="other-blog-posts-div">
              {blogs.slice(1).map((data, idx) => {
                return (
                  <div
                    className={`${clsname[0]} ${
                      blogs.slice(1).length % 2 !== 0 &&
                      idx === blogs.slice(1).length - 1 &&
                      clsname[1]
                    }`}
                    key={idx}
                  >
                    <div className="other-singlepost-image">
                      <img
                        src={`${blogApiUrl}${data?.coverImg?.url}`}
                        alt={data?.coverImg?.name}
                      />
                    </div>
                    <div className="blog-data-and-buttons">
                      <p>{data.title}</p>
                      <p>
                        {data.description?.length > 135
                          ? data.description?.slice(0, 130) + "..."
                          : data?.description}
                      </p>
                      <button
                        className="blog-readmore-btn-style"
                        onClick={() => {
                          scrollToTop();
                          navigate(`/blog/${data.slug}`);
                        }}
                      >
                        <p>Read More</p>
                        <div className="readmore-btn-dash" />
                        <MdOutlineArrowForwardIos fill="#3FA0EF" size="2rem" />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          
        </div>
      ) : (
        <div>No blogs Found</div>
      )}
    </div>
  );
};

export default Blogs;
