import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "./VideoModal.css";

const VideoModal = ({ open, setOpen }) => (
  <Dialog.Root open={open} onOpenChange={setOpen} modal={false}>
    <Dialog.Portal>
      {/* <Dialog.Overlay className="DialogOverlay" /> */}
      <Dialog.Content className="DialogContent">
        {/* <Dialog.Title className="DialogTitle">Demo</Dialog.Title> */}
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/sTjpH34C1qA?si=XjH6O46AyPqKJryx&amp;controls=0&autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
        {/* <Dialog.Close asChild>
          <button
            className="IconButton"
            aria-label="Close"
            onClick={() => setOpen(!open)}
          >
            <MdClose />
          </button>
        </Dialog.Close> */}
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default VideoModal;
