import React from "react";
import "./workDisplay.css";

import icon1 from "../../../../assets/images/homePage/Icon.png";
import icon2 from "../../../../assets/images/homePage/Icon-1.png";
import icon3 from "../../../../assets/images/homePage/Icon-2.png";

import workImage from "../../../../assets/images/homePage/Group8.png";

const WorkDisplay = () => {
  return (
    <div className="work-display-main">
      <div className="work-display-divs work-display-textarea-up">
        <div className="work-display-title">
        Upgrade your hiring process with Hyreworks, an all-in-one platform designed to simplify your talent acquisition.
        </div>
        <div className="work-display-desc">
        We handpick candidates to ensure you’re only choosing from the best talent pool. Many leading companies build teams here within 2 weeks and speed up their recruitment process without compromising on the quality of hire.
        </div>
      </div>
      <div className="work-display-divs work-display-textarea-down">
        <div className="work-icon-and-text">
          <img className="icon icon-1" src={icon1} alt="icon" />
          <div className="textarea-icon-text">
          High-quality pre-screened candidates
          </div>
        </div>
        <div className="work-icon-and-text">
          <img className="icon icon-1" src={icon2} alt="icon" />
          <div className="textarea-icon-text">
          Get the best visibility for your job
          </div>
        </div>
        <div className="work-icon-and-text">
          <img className="icon icon-1" src={icon3} alt="icon" />
          <div className="textarea-icon-text">
          Instant connections with candidates & chat
          </div>
        </div>
      </div>
      <img
        className="work-display-divs work-dsiplay-image"
        src={workImage}
        alt="work-display-image"
      />
    </div>
  );
};

export default WorkDisplay;
