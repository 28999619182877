import React, { useRef, useState } from "react";
import "./serviceCarousel.css";
import { ServiceCarouselData } from "./serviceCarouselData";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { serviceCarouselSettings } from "../../../../utils/serviceCarouselSettings";

const ServiceCarousel = () => {
  const [progressVal, setProgressVal] = useState(1.5);
  const serviceSliderRef = useRef();

  const sliderChangeCallback = (currentSlide, nextSlide) => {
    let calc = (nextSlide / (ServiceCarouselData.length - 1)) * 100;
    setProgressVal(calc);
  };

  return (
    <div className="service-carousel-main">
      <div className="service-carousel-heading">
        <h2 className="service-carousel-title">
          Explore{" "}
          <span className="service-subtitle">
            Our Services<span className="primary-color-text">.</span>
          </span>{" "}
        </h2>
        <div className="service-carousel-actions">
          <div
            className="left-btn"
            onClick={() => serviceSliderRef.current.slickPrev()}
          >
            <FaChevronLeft size={20} />
          </div>
          <div
            className="right-btn"
            onClick={() => serviceSliderRef.current.slickNext()}
          >
            <FaChevronRight size={20} />
          </div>
        </div>
      </div>
      <div className="service-carousel-container">
        <Slider
          ref={serviceSliderRef}
          beforeChange={sliderChangeCallback}
          {...serviceCarouselSettings}
          className="service-carousel-slider"
        >
          {ServiceCarouselData.map((item, key) => {
            return (
              <div className="individual-item" key={key}>
                <img
                  className="service-carousel-icon"
                  src={item.icon}
                  alt="service-category-icons"
                />
                <div className="service-carousel-category">{item.text}</div>
              </div>
            );
          })}
        </Slider>
        {/* progress bar of slider */}
        <div className="service-slider-progressbar">
          <div
            className="service-slider-progressDot"
            style={{ left: progressVal + "%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceCarousel;
