import icon1 from "../../../../assets/images/homePage/carouselIcon1.png"
import icon2 from "../../../../assets/images/homePage/carouselIcon2.png"
import icon3 from "../../../../assets/images/homePage/carouselIcon3.png"
import icon4 from "../../../../assets/images/homePage/carouselIcon4.png"
import icon5 from "../../../../assets/images/homePage/carouselIcon5.png"
import icon6 from "../../../../assets/images/homePage/carouselIcon6.png"




export const ServiceCarouselData = [
    {
        icon: icon1,
        text: "Job Posting and Distribution",
    },
    {
        icon: icon2,
        text: "Applicant Tracking System",
    },
    {
        icon: icon3,
        text: "Candidate Screening and Assessment",
    },
    {
        icon: icon4,
        text: "Candidate Management",
    },
    {
        icon: icon5,
        text: "Interview Scheduling",
    },
    {
        icon: icon6,
        text: "Background Checking and Verification",
    },
]