import React from "react";
import "./InfoHeader.css";

const InfoHeader = ({ title, heading, description }) => {
  return (
    <div className="infoHeader-header-section">
      <div className="infoHeader-title">
        <div className="infoHeader-heading-dash" />
        <div>{title}</div>
      </div>
      <div className="infoHeader-heading-and-desc">
        <h3 className="infoHeader-heading">{heading}<span className="infoHeader-heading-period-style">.</span></h3>
        <div className="infoHeader-description">{description}</div>
      </div>
    </div>
  );
};

export default InfoHeader;
