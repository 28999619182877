export const cardData = [
    {
        title: "AI Based Analytics and Reporting",
        desc:"Provides analytics and reporting features to track key recruitment metrics, such as time-to-fill, source effectiveness, and candidate quality, allowing employers to evaluate and optimize their hiring strategies.",
    },
    {
        title: <>Job Posting and <span className="WhatWeHave-subtitle">Distribution</span></>,
        desc:"Allows employers to create and post job listings, reaching a wide audience through various online channels such as job boards, social media platforms, and partner websites",
    },
    {
        title: <>Applicant Tracking <span className="WhatWeHave-subtitle">System (ATS)</span></>,
        desc:"Enables employers to manage the entire recruitment process, from receiving applications to shortlisting candidates and scheduling interviews. It includes features like resume parsing, candidate communication, and collaboration tools for hiring teams.",
    },
    {
        title: "Candidate Screening and Assessment",
        desc:"Provides tools for screening and evaluating candidates, including pre-employment assessments, skills tests, and video interviews, helping employers identify the most qualified candidates efficiently.",
    },
    {
        title: "Candidate Management",
        desc:"Helps employers organize and manage candidate profiles, including storing resumes, notes, and communication history, streamlining the candidate selection process and ensuring a positive candidate experience.",
    },
    {
        title: "Interview Scheduling",
        desc:"Facilitates the scheduling of interviews with candidates through automated scheduling tools, calendar integrations, and communication reminders, reducing scheduling conflicts and improving the efficiency of the hiring process",
    },
    {
        title: "Background Checking and Verification",
        desc:"Offers services for conducting background checks, reference checks, and verifying employment history and credentials, ensuring employers make informed hiring decisions and mitigate risks",
    },
    {
        title: "Onboarding Support",
        desc:"Offers support for onboarding new hires, including electronic document signing, orientation materials, and task management tools, ensuring a smooth transition for new employees into the organization.",
    },
    {
        title: "Integration with HR Systems",
        desc:"Integrates with existing HR systems, such as payroll and employee management software, to streamline data exchange and ensure consistency across the employee lifecycle.",
    },
]