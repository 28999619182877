import React from "react";
import "./WhatWeHave.css";

const WhatWeHave = () => {
  const dataContent = [
    {
      number: "01",
      title: <>Streamlined <span className="WhatWeHave-subtitle">Recruitment Process</span></>,
      description:
        "Revolutionizing hiring with a user-friendly platform that simplifies every step, from posting jobs to interviewing candidates, saving time and effort for employers.",
    },
    {
      number: "02",
      title: <>Advanced <span className="WhatWeHave-subtitle">Candidate Matching</span></>,
      description:
        "Harnessing cutting-edge algorithms to precisely match employers with the most qualified candidates, ensuring a seamless fit for both parties and enhancing overall recruitment success.",
    },
    {
      number: "03",
      title: "Data-Driven Insights",
      description:
        "Empowering employers with valuable analytics and insights to make informed hiring decisions, optimizing recruitment strategies, and driving organizational growth.",
    },
  ];
  return (
    <div className="wwh-cards-container">
      {dataContent.map((data, idx) => {
        return (
          <div className="wwh-single-card" key={idx}>
            <div className="wwh-number-and-title">
              <div className="wwh-number">{data.number}</div>
              <h4 className="wwh-title">{data.title}</h4>
              <div className="wwh-desc">
                {data.description.substring(0, 215)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WhatWeHave;
