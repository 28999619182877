import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

import mapIcon from "../../assets/images/homePage/mapIcon.png";
import mailIcon from "../../assets/images/homePage/mailIcon.png";
import dialerIcon from "../../assets/images/homePage/dialerIcon.png";

import "./ContactForm.css";

const ContactForm = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    messages: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);


  // const formSubmissionUrl = "https://crm.zoho.com/crm/WebToLeadForm";
  const formSubmissionUrl =
    "https://digicoin-reboot.dmlabs.in/api/dml-newsletter";

    const onChangeHandler = (e) => {
      const { name, value } = e.target;
      
      setData({
        ...data,
        [name]: value,
      });
    
      // Validate the specific field
      const newErrors = validate({
        ...data,
        [name]: value,
      });
      
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: newErrors[name], // Update only the specific field's error
      }));
    };
    
    

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const errors = validate(data);
    if (Object.keys(errors).length === 0) {
      let validFirstName = data.firstName.trim();
      let validLastName = data.lastName.trim();
      let finalData = {
        ...data,
        firstName: validFirstName,
        lastName: validLastName,
      };
      handleFormSubmit(finalData);
    } else {
      setFormErrors(errors);
    }
  };

  const handleFormSubmit = async (finalData) => {
    try {
      const response = await axios.post(formSubmissionUrl, finalData);
      if (response.status === 200) {
        setFormSubmitted(true);

        setData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          messages: "",
        });
        
        setTimeout(() => {
          alert("Form submitted successfully!");
        }, 100); 
        setFormErrors({});
      } else {
        console.error("Error submitting form:", response.statusText);
      }
     
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // function for validating all input fields
  const validate = (data) => {
    let errors = {};

    const emailRegex = /^(?!.*\.\.)([A-Z0-9._%+-]+)@[A-Z0-9-]+\.[A-Z]{2,}$/i;
    const nameRegex = /^[A-Za-z]{3,}(?: [A-Za-z]+)*$/;
    const phonePattern = /^[0-9]{10}$/;

    if (!data.firstName) errors.firstName = "First Name field cannot be empty";
    else if (data.firstName.length < 3)
      errors.firstName = "First Name cannot be less than 3 characters";
    else if (data.firstName.length > 100)
      errors.firstName = "First Name cannot be greater than 100 characters";
    else if (!nameRegex.test(data.firstName))
      errors.firstName = "Invalid First Name";

    if (!data.lastName) errors.lastName = "Last Name field cannot be empty";
    else if (data.lastName.length < 3)
      errors.lastName = "Last Name cannot be less than 3 characters";
    else if (data.lastName.length > 100)
      errors.lastName = "Last Name cannot be greater than 100 characters";
    else if (!nameRegex.test(data.lastName))
      errors.lastName = "Invalid Last Name";

    if (!data.email) errors.email = "Email field cannot be empty";
    else if (!emailRegex.test(data.email)) errors.email = "Email is not valid";

    if (!data.phone.match(phonePattern)) {
      errors.phone = "Please enter a valid phone number.";
    }

    if (!data.messages) errors.messages = "Message field cannot be empty";

    setIsFormValid(Object.keys(errors).length === 0);
    return errors;
  };

  return (
    <div
      className="contact-wrapper"
      id="contactFormRef"
      style={{ scrollMargin: "150px" }}
    >
      <div className="contact-text-and-form-container">
        <div className="contact-text-allinfo-container">
          <div className="contact-text-heading-desc-container">
            <h2 className="">Let's talk with Us</h2>
            <p>
              Have a project in mind that you think we’d be a great fit for it?
              We’d love to know what you’re thinking.
            </p>
          </div>
          <p>Contact Info :</p>
          <div className="contact-text-info-list">
            <div className="single-contact-info">
              <div className="icon-wrapper">
                <div className="single-contact-info-list-style" />
                <img
                  src={dialerIcon}
                  className="contact-icon"
                  alt="dialer-icon"
                />
              </div>
              <div className="single-contact-info-detail">
                Phone Number: 0172-6131700
              </div>
            </div>
            <div className="single-contact-info">
              <div className="icon-wrapper">
                <div className="single-contact-info-list-style" />
                <img src={mailIcon} className="contact-icon" alt="email-icon" />
              </div>
              <div className="single-contact-info-detail">
                Email: hello@hyreworks.com
              </div>
            </div>
            <div className="single-contact-info">
              <div className="icon-wrapper">
                <div className="single-contact-info-list-style" />
                <img src={mapIcon} className="contact-icon" alt="map-icon" />
              </div>
              <div className="single-contact-info-detail">
                Map Street: C - 212, Ground Floor, Phase 8B, Sector 74, Mohali
              </div>
            </div>
          </div>
          <div className="contact-icons">
            <a
              href="https://www.instagram.com/hyreworks/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram
                color="#000"
                size={32}
                className="socialmedia-icon"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/hyre-works"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin
                color="#000"
                size={32}
                className="socialmedia-icon"
                style={{ background: "transparent" }}
              />
            </a>
            <a
              href="https://x.com/HyreWorks"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter
                color="#000"
                size={32}
                className="socialmedia-icon"
                style={{ background: "transparent" }}
              />
            </a>
          </div>
        </div>
        {/* *********************** */}
        <form
          id="lets-talk-form"
          name="WebToLeads5267894000001348003"
          onSubmit={onSubmitHandler}
          acceptCharset="UTF-8"
          className="contactformCSS"
          autoComplete="off"
        >
          <div className="input-container">
            <div className="firstName-wrap">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="John"
                value={data.firstName}
                onChange={onChangeHandler}
              />
              {Object.keys(formErrors).length > 0 && formErrors.firstName ? (
                <span className="contactform-errorField">
                  {formErrors.firstName}
                </span>
              ) : null}
            </div>
            <div className="lastName-wrap">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Smith"
                value={data.lastName}
                onChange={onChangeHandler}
              />
              {Object.keys(formErrors).length > 0 && formErrors.lastName ? (
                <span className="contactform-errorField">
                  {formErrors.lastName}
                </span>
              ) : null}
            </div>
          </div>
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="johnsmith@gmail.com"
            value={data.email}
            onChange={onChangeHandler}
          />
          {Object.keys(formErrors).length > 0 && formErrors.email ? (
            <span className="contactform-errorField">{formErrors.email}</span>
          ) : null}
          <label htmlFor="email">Phone</label>
          <input
            type="text"
            name="phone"
            id="phone"
            placeholder="9999999999"
            value={data.phone}
            onChange={onChangeHandler}
          />
          {Object.keys(formErrors).length > 0 && formErrors.phone ? (
            <span className="contactform-errorField">{formErrors.phone}</span>
          ) : null}
          <label htmlFor="messages">Messages</label>
          <textarea
            name="messages"
            id="messages"
            placeholder="I have a query regarding..."
            value={data.messages}
            onChange={onChangeHandler}
          />
          {Object.keys(formErrors).length > 0 && formErrors.messages ? (
            <span className="contactform-errorField">
              {formErrors.messages}
            </span>
          ) : null}
          <button
            type="submit"
            id="formsubmit"
            className={` ${!isFormValid ? 'disabled-submit-button' : "contactform-submitBtn" } `}
            disabled={!isFormValid}
          >
            Request a Demo
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
